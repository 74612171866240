:focus {
    outline: none;
}
.booking-form form input,
::placeholder, input, textarea, select{
    font-family: "Roboto", sans-serif;
    color: #777777;
}
.preloader{
    display: flex;
}
.offcanvas-wrapper .offcanvas-widget{
    overflow-x: auto;
}
header .nav-container.breakpoint-on .nav-menu .menu-items ul li.menu-item-has-children>a::after{
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    display: block;
    position: absolute;
    right: 0;
    height: 45px;
    width: 45px;
    top: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 2;
    background: transparent;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}
.booking-form form .inputs-filed .nice-select select {
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-appearance: none;
}
.input-group.input-group-two .nice-select,
.booking-form form .inputs-filed .nice-select {
    padding: 0;
    border: none;
}
.booking-form form .nice-select, .booking-form form input, .booking-form form select {
    background-color: transparent;
    width: 100%;
    height: 50px;
    font-size: 14px;
    border: none;
    border-bottom: 2px solid #bead8e;
    line-height: 50px;
    color: #777777;
}
.input-group.input-group-two .nice-select::before {
    left: auto;
    right: 30px;
}
.input-group.input-group-two .nice-select select{
    margin-left: 0;
    padding: 0 50px 0 30px;
    -webkit-appearance: none;
    font-size: 14px;
}
.nice-select::before {
    content: '\f063';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    position: absolute;
    left: 30px;
    top: 50%;
    color:#008d49;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation{
    background: transparent;
    width: 10px;
    height: 10px;
    letter-spacing: normal;
    border: 0.45rem solid transparent;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next{
    border-left-color: #ccc!important;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous{
    border-right-color: #ccc!important;
}
.latest-news .latest-post-box .post-desc .post-meta{
    font-size: 14px;
}
.latest-news .latest-post-box .post-desc .post-meta {
    margin-bottom: 20px;
    display: flex;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.latest-news .latest-post-box .post-desc h4{
    margin-bottom: 15px;
}
.latest-news .latest-post-box .post-desc {
    padding: 25px;
}
.post-box .post-desc .post-footer .author img{
    width:40px;
    height: 40px;
    object-fit: cover;
}
.news-details-box .entry-content .cat + .cat,
.post-box .post-desc .cat + .cat{
    margin-left: 15px;
}
.sidebar .widget.popular-feeds .single-popular-feed .feed-img{
    width: 80px;
    height: 80px;
}
.sidebar .widget.popular-feeds .single-popular-feed .feed-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.news-details-box .entry-footer .post-nav .next-post::before{
    content: '';
    background-image: url('assets/img/blog-details/icon.png');
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}
.counter-section .counter-box.counter-box-two h4{
    display:inline-block;
}
.counter-section .counter-box.counter-box-two h4 + .prefix{
    color: #222;
    letter-spacing: -1px;
    font-family: "Old Standard TT", serif;
    font-size: 60px;
    font-weight: 400;
    line-height: 1.2;
}
@media (max-width:767px){
    .news-details-box .entry-footer .post-nav .next-post::before{
        display: none;
    }
}